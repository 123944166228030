import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Card, Heading, Flex, Paragraph} from 'reakit'

import {
	Container,
	TopBar,
	HomeFooter,
} from '../../components/layouts'

import {
	Loader,
	Link,
	DiscoverCard,
	Image,
	IconInput,
	Input
} from '../../components/ui'

import Layout from '../../components/layout'

import {
	createNewOrg,
	searchForOrg,
	getCategories,
	getDiscover,
	clearSearch
} from '../../factory'

import theme from '../../theme/content'
import { navigate } from 'gatsby'
import {getUrlVars} from '../../util'

class Register extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			position: 0,
			pending: false,
			matchingOrgs: [],
			selectedCategory: -1,
		}

		this.searchOrgs = this.searchOrgs.bind(this)
		this.selectOrganization = this.selectOrganization.bind(this)
		this.onSelectCategory = this.onSelectCategory.bind(this)
	}

	componentDidMount() {
		this.props.getDiscover()
		this.props.getCategories()
		this.redirectToOrg(getUrlVars().oid)
	}

	redirectToOrg(oid) {
		if(oid) {
			navigate(`/register/org/${oid}/`)
		}
	}

	setRegisterValues(e) {
		if (e.target) {
			this.setState({
				[e.target.name]: e.target.value,
			})
		}
	}

	searchOrgs(e) {
		const title = e.target.value

		if (title.length < 2) {
			this.props.clearSearch()
		} else {
			this.props.searchForOrg(title)
		}

		const orgs = this.props.organizations

		if(e.target.value === '') {
			this.setState({
				matchingOrgs: [],
			})
			return
		}

		if (!orgs || !orgs.length) return

		const filteredOrgs = orgs.filter(org => (
			org.title.toUpperCase().indexOf(e.target.value.toUpperCase()) > -1
		))

		this.setState({
			matchingOrgs: filteredOrgs,
		})
	}

	selectOrganization(org) {
		if (org.organization_id === 0) {
			this.props.createNewOrg(org.ein, (oid) => navigate(`/register/org/${oid}/`) )
		} else {
			navigate(`/register/org/${org.organization_id}/`)
		}
	}

	onSelectCategory(e) {
		const category_id = parseInt(e.target.value, 10)

		this.setState({selectedCategory: category_id}, ()=>{
			if (category_id === -1) {
				this.props.getDiscover()
			} else {
				this.props.getDiscover({category_id})
			}
		})
	}


	render() {
		const {categories, organizations, pending, searchResults} = this.props
		const {selectedCategory, matchingOrgs} = this.state

		const loginContent = theme.pagesContent.login

		let primary = organizations && organizations.filter(org => org.is_primary_featured)
		let featured = organizations && organizations.filter(org => org.is_featured && !org.is_primary_featured )
		let rest = organizations && organizations.filter(org => !org.is_featured && !org.is_primary_featured)

		if(matchingOrgs.length > 0) {
			rest = []
			featured = matchingOrgs
			primary = []
		}
		if(matchingOrgs.length === 0 && searchResults.length > 0) {
			rest = searchResults
			featured = []
			primary = []
		}

		return (
			<Layout>
				<TopBar />
				<Container bg={loginContent.bg}>
					<Flex
						width={'100%'}
						alignItems={'center'}
						justifyContent={'start'}
						flexDirection={'column'}
						paddingTop={100}
						paddingBottom={50}
					>
						<Card
							width="80%"
							border={`1px solid ${theme.colors.lightGray}`}
							minWidth="350px"
							display={'flex'}
							flexDirection={"column"}
							height={'auto'}
							padding={10}
						>
							<Heading as="h1" margin="0" lineHeight={'1em'}>First things first - What's your cause?</Heading>
							<Paragraph marginTop={10} marginBottom={10}>Search for and pick a cause you are passionate about from our curated list of our organizations</Paragraph>
							<Flex flexWrap={'wrap'} justifyContent={'space-between'} marginBottom={'0'}>
								<Flex style={{ marginBottom: 25, flex: "1 1 250px", }} column>
									<Heading as="h1" fontSize={18}>Search for an organization</Heading>
									<IconInput icon={'fas fa-search'} onChangeSearch={this.searchOrgs}/>
								</Flex>
								<Flex style={{ width: '85%', flex: "1 1 250px", paddingLeft: '20px', marginBottom: 0}} column>
									<Heading as="h1" fontSize={18}>Filter by category</Heading>
									<Input
										name={'payment_account'}
										field={'select'}
										options={[{category_id: -1, title: "All Categories"}].concat(categories)}
										valueSelector={'category_id'}
										labelSelector={"title"}
										placeholder={'$100'}
										type={'text'}
										maxWidth={'400px'}
										defaultValue={selectedCategory}
										setValues={(e) => this.onSelectCategory(e)}
									/>
								</Flex>
							</Flex>
							{pending && <Flex row width={300} justifyContent={'center'} alignItems={'center'}><Paragraph style={{margin: 'auto', alignItems: 'left'}}>Getting organizations... </Paragraph><Loader/></Flex>}
							{primary.length > 0 &&
							<Flex style={{width: '100%', alignItems: 'center', flex: 1, justifyContent: 'center', margin: 0, marginTop: 15}}>
								<DiscoverCard
									onSelectOrganization={this.selectOrganization}
									organization={primary[0]} primary/>
							</Flex>
							}
							{
								featured.length > 0 &&
							<Flex style={{width: '100%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: 0, marginTop: 15}}>
								<Flex style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', width: '100%'}}>
									{featured.map((item, index) => (<DiscoverCard
										key={item.organization_id + '_' + index}
										onSelectOrganization={this.selectOrganization}
										organization={item}/>))}
								</Flex>
							</Flex>


							}
							{(featured.length > 0 || primary.length > 0) && matchingOrgs.length === 0 &&
							<div style={{textAlign: 'center'}}>
								<Image
									src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding1.png"}
									height={'auto'}
									width={100}
									margin={'auto'}
								/>
								<Heading as="h1" fontSize="18px">Looking for your organization?</Heading>
							</div>
							}
							{
								rest.length > 0 &&
							<Flex style={{width: '100%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', margin: 0, marginTop: 15 }}>
								<Flex style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', width: '100%'}}>
									{rest.map((item, index) => (<DiscoverCard
										key={item.organization_id + '_' + index}
									 onSelectOrganization={this.selectOrganization}
									 organization={item}/>))}
								</Flex>
							</Flex>
							}
							<Flex margin="0" row>
								<Heading as="h6" margin="0 0.5rem 0 0">
									<Link
										as="a"
										href={'/login/'}
									>
										Login
									</Link>
								</Heading>
								<Heading as="h6" margin="0 0.5rem 0 0">
										•
								</Heading>
								<Heading as="h6" margin="0">
									<Link as="a" href={loginContent.forgot.link}>
										{loginContent.forgot.text}
									</Link>
								</Heading>
							</Flex>
						</Card>
					</Flex>
				</Container>
				<HomeFooter />
			</Layout>
		)
	}
}

Register.propTypes = {
	pageContext: PropTypes.any,
	user: PropTypes.object,
	organizations: PropTypes.array,
	pending: PropTypes.bool,
	categories: PropTypes.array,
	error: PropTypes.string,
	getDiscover: PropTypes.func,
	getCategories: PropTypes.func,
	searchResults: PropTypes.array,
	searchForOrg: PropTypes.func,
	createNewOrg: PropTypes.func,
	clearSearch: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		user: state.authState.user,
		organizations: state.discoverState.organizations,
		pending: state.discoverState.pending,
		error: state.authState.error,
		categories: state.discoverState.categories,
		searchResults: state.discoverState.searchResults
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getDiscover: (data) => dispatch(getDiscover(data)),
		getCategories: () => dispatch(getCategories()),
		searchForOrg: (title)=>dispatch(searchForOrg(title)),
		clearSearch: () => dispatch(clearSearch()),
		createNewOrg: (ein, callback) => dispatch(createNewOrg(ein, callback))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Register)
